import { FETCH_GROUPS } from '../actions/groups';

const initialState = {
  count : 0,
  groupsList: [],
  error: false
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUPS:
      const { groups } = action.payload
      return {
        count : groups.data.count,
        groupsList : groups.data.list,
        error: !groups.success
      }

    default: return state
  }
}

export default groups