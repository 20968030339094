//Cambiarlo para funcionar con el endpoint ./groupServices
import groupServices from '../../services/groupServices'

export const FETCH_GROUPS = 'FETCH_GROUPS'

export const loadGroups = (groups) => ({
  type: FETCH_GROUPS,
  payload: { groups }
})

export const fetchGroups = (filters) => (dispatch) => {
  //Esto espera una respuesta, es un promise al backend
  groupServices.getGroups(filters)
    .then((groups) => dispatch(loadGroups(groups)))
    .catch((error) => console.log(error))
}

