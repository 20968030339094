//CODIGOS DE RESPUESTA DEL SISTEMA
//REFERENCIA: http://www.restapitutorial.com/httpstatuscodes.html

import Strings from '../strings'
export default  {
    STATUS_CODE_DIVIDER: 1000,
    //200
    SUCCESS: {code: 200001,  message: Strings.success},
    REQUEST_VALIDATE_SUCCESSFULLY: {code: 200002,  message: 'Request validated successfully'},
    //204
    EMPTY_RESPONSE: {code: 204001,  message: 'No data found'},
    //206
    CREDIT_CARD_TOKENIZATION_REQUEST_APPROVED: {code: 206002,  message: 'The request already aproved'},
    CREDIT_CARD_TOKENIZATION_REQUEST_REJECTED: {code: 206003,  message: 'The request has been rejected or is pending'},
    DUPLICATE_ENTRY: {code: 206004,  message: 'Duplicate entry'},
    //400
    TOKEN_EXPIRED: {code: 400001,  message: 'Token expired'},
    TOKEN_NOT_EXPIRED: {code: 400002,  message: 'Token is not expired'},
    BAD_REQUEST: {code: 400003,  message: 'Bad request'},
    //403
    INVALID_TOKEN: {code: 403001,  message: 'Token is invalid or corrupt'},
    //401
    USER_INACTIVE: {code: 401001,  message: Strings.user_inactive_message},
    INVALID_CREDENTIALS: {code: 401002,  message: Strings.invalid_credentials},
    USER_IN_PROCESS: {code: 401003,  message: 'The user is in validation process'},
    //428
    GET_USER_ERROR: {code: 428001,  message: 'Error getting user by id'},
    EMAIL_NOT_FOUND: {code: 428002,  message: 'The email no exit'},
    RECORD_EXISTS: {code: 428003,  message: 'The register already exists'},
    UPDATE_USER_ERROR: {code: 428004,  message: 'Error updating user'},
    EMAIL_FOUND: {code: 428005,  message: 'The email exists'},


    //500
    PAYMENT_PROVIDER_ERROR: {code: 500001,  message: 'Error with payment provider'},
    CREDIT_CARD_TOKENIZATION_ERROR: {code: 500003,  message: 'Error tokenizing credit card'},
    SEND_EMAIL_ERROR: {code: 500004,  message: 'Error sending email'},
    addDataToMessage: (jsonMessage, customData) => {
        if(customData != null && customData != undefined) {
            return Object.assign({}, jsonMessage, { data: customData });
        }
        return jsonMessage;
    }
};
