import React, { Component } from 'react';
import {Route, Switch, BrowserRouter } from 'react-router-dom';
//Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { renderRoutes } from 'react-router-config';
import './App.scss';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/typography.css';
//import '@ionic/react/css/structure.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const ResetPassword = React.lazy(() => import('./views/Pages/ResetPassword'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

toast.configure({
  autoClose: 2000,
  closeOnClick: true,
  hideProgressBar: false,
})

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/resetPassword" name="Reset Password Page" render={props => <ResetPassword {...props} />} />
            {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} /> */}
            {/* <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} /> */}
            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
