import { combineReducers } from 'redux';
import StudentsReducer from './students';
import TeachersReducer from './teachers';
import GroupsReducer from './groups';
import UsersReducer from './users';
import IndividualClassesReducer from './individualClasses';
import CrmReducer from './crm';
const rootReducers = combineReducers({
    students: StudentsReducer,
    teachers: TeachersReducer,
    groups: GroupsReducer,
    users: UsersReducer,
    individualClasses: IndividualClassesReducer,
    issues: CrmReducer,
    // demoAndPlacementClasses: demoAndPlacementClasses
})

export default rootReducers