import { FETCH_STUDENTS } from '../actions/students';

const initialState = {
  count:0,
  studentsList: [],
  error: false
};

const students = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDENTS:
      const { students } = action.payload
      return {
        count: students.data.count,
        studentsList : students.data.list,
        error: !students.success
      }

    default: return state
  }
}

export default students