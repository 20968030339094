//Cambiarlo para funcionar con el endpoint ./studentServices
import teacherServices from '../../services/teacherServices'

export const FETCH_TEACHERS = 'FETCH_TEACHERS'

export const loadTeachers = (teachers) => ({
  type: FETCH_TEACHERS,
  payload: { teachers }
})

export const fetchTeachers = (filters) => (dispatch) =>{
  //Esto espera una respuesta, es un promise al backend
  teacherServices.getTeachers(filters)
  .then((teachers) => dispatch(loadTeachers(teachers)))
  .catch((error) => console.log(error))
}

