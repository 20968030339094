import Axios from 'axios'
import Utils from '../utils/Utils'
import Constants from '../utils/constants'
// Enviroments
import enviroment from '../enviroments/enviroments';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1'
const HEADERS = {
  'Content-Type': 'application/json'
}
// New Monolithic backend URL
const URLV2 = "https://tingoapi.herokuapp.com/v1";
const getToken = () => Utils.getCookieToken();


export default {

  createGroup: (payload) => Axios.post(`${BASE_URL}/group`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  createGroupUser: (payload) => Axios.post(`${BASE_URL}/groupUser`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  getGroupByTeacher: (id) => Axios.get(`${BASE_URL}/getGroupsByTeacher/${id}`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  getNextClassByTeacher: (id) => Axios.get(`${BASE_URL}/getNextClassByTeacher/${id}`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  getGroupByCode: (groupCode) => Axios.get(`${BASE_URL}/getGroupByCode/${groupCode}`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  getGroups: (filters) => Axios.post(`${BASE_URL}/getGroupsFiltered`, filters, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => {
    return res.data;
  }).catch(res => {
    return res.response.data
  }),

  getGroup: (groupCode) => Axios.get(`${BASE_URL}/getGroup/${groupCode}`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  getGroupsWhitoutSupervisor: (groupCode) => Axios.get(`${BASE_URL}/getGroupsWhitoutSupervisor/${groupCode}`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  getStudentsByGroup: (id) => Axios.get(`${BASE_URL}/getStudentsByGroup/${id}`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  getStudentProgress: (studentData) => Axios.post(`${BASE_URL}/getStudentProgress`, studentData, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => {
    return res.data;
  }).catch(res => {
    return res.response.data
  }),

  getHoursInformationByUser: (studentData) => Axios.post(`${BASE_URL}/getHoursInformationByUser`, studentData, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => {
    return res.data;
  }).catch(res => {
    return res.response.data
  }),

  updateGroup: (payload) => Axios.put(`${BASE_URL}/group`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
  deleteGroup: (groupCode) => Axios.post(`${BASE_URL}/group`, {
    id: groupCode,
    deleted: Constants.DELETED_VAL.TRUE
  }, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),

  inactivateGroupUser: (payload) => Axios.put(`${BASE_URL}/inactivateGroupUser`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  activateGroupUser: (payload) => Axios.put(`${BASE_URL}/activateGroupUser`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then((res) => res.data),

  changeGroupLanguageLevel: async (languageLevel, groupId, customHours) => {
    const data = {
      languageLevelId: languageLevel,
      groupId,
      customHours
    }
    return Axios.put(`${URLV2}/user/group/change-level`, data);
  },
  //_____________________________________________________________________Classes services_______________________________________________________________________________________________
  getClassesFromGroup: (groupCode) => Axios.get(`${BASE_URL}/getClassesFromGroup/${groupCode}`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
  getStudentsByClassAndGroup: (payload) => Axios.post(`${BASE_URL}/getStudentsByClassAndGroup`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
  checkAttendance: (payload) => Axios.post(`${BASE_URL}/createAttendance`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
  getClassInfo: (classId) => Axios.get(`${BASE_URL}/getClass/${classId}`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
  updateClass: (payload) => Axios.put(`${BASE_URL}/class`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
  changeClassState: (payload) => Axios.post(`${BASE_URL}/changeClassState`, payload, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
  deleteClass: (classId) => Axios.delete(`${BASE_URL}/class`, {
    id: classId,
    deleted: 1
  }, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
  getUnratedClasses: (classId) => Axios.get(`${BASE_URL}/unratedClasses`, {
    headers: {
      ...HEADERS,
      Authorization: getToken()
    }
  }).then(res => res.data),
}
