import { FETCH_ISSUES } from '../actions/crm';

const initialState = {
    count: 0,
    issuesList: [],
    error: false
};

const issues = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ISSUES:
            const { issues } = action.payload
            return {
                count: issues.data.count,
                issuesList: issues.data.list,
                error: !issues.success
            }

        default: return state
    }
}

export default issues;