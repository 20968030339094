import Axios from 'axios'
import Utils from '../utils/Utils'
import Constants from '../utils/constants'
// Enviroments
import enviroment from '../enviroments/enviroments';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1'
// New Monolithic backend URL
const URLV2 = enviroment.getUrl();
const HEADERS = {'Content-Type' : 'application/json'}

// const STUDENTS_URL = `${BASE_URL}/getAllUsers`

const getToken = () => Utils.getCookieToken();

export default  {

    /*
        This function fetch a batch of users to the backend
        params : {filters: {…}, page: 1} -> page is the paginate to filter in the db
        return : batch of students
    */
    getStudents: (filters) => Axios.post(`${BASE_URL}/getStudentsFiltered`, filters, {headers : {...HEADERS, Authorization : getToken()}})
        .then(res => { 
            return res.data
        })
        .catch(err => err),
    /*
        this function fetch the active users by month or week
        params : date to filter students
        return : active users in the range of given date and now
    */
    getActiveUsers : () => Axios.get(`${BASE_URL}/getUsers`, {headers : {...HEADERS, Authorization : getToken()}})
        .then(res => {
            return res.data
        }),
    /*
        this function fettch available groups to match an user
        params : languageLevel : number
    */
    getGroupsToMatch : (languageLevel) => Axios.get(`${URLV2}/user/groups/match?level=${languageLevel}`, {headers : {...HEADERS, Authorization : getToken()}}),
    getActiveLanguageLevels : (languageLevel) => Axios.get(`${URLV2}/user/languages/levels?idLanguage=${languageLevel}`),
    getActiveLanguages : () => Axios.get(`${URLV2}/user/languages/all`),
    getStudent: (id) => Axios.get(`${BASE_URL}/getUser/${id}`, {headers : {...HEADERS, Authorization : getToken()}}).then((res) => res.data),
    getStudentByDocument: (payload) => Axios.post(`${BASE_URL}/getUsersByDocument`, payload, {headers : {...HEADERS, Authorization : getToken()}}),
    getStudentByEmail: (email) => Axios.get(`${BASE_URL}/getStudentsByEmail/${email}`, {headers : {...HEADERS, Authorization : getToken()}}).then((res) => res.data),
    getGroupsByStudentId: (id) => Axios.get(`${BASE_URL}/getGroupsByStudentId/${id}`, {headers : {...HEADERS, Authorization : getToken()}}).then((res) => res.data),
    
    getTeacherByEmail: (email) => Axios.get(`${BASE_URL}/getTeacherByEmail/${email}`, {headers : {...HEADERS, Authorization : getToken()}}).then((res) => res.data),
    getUserByFullname: (userType, name) => Axios.get(`${BASE_URL}/getUserByFullname/${userType}/${name}`, {headers : {...HEADERS, Authorization : getToken()}}).then((res) => res.data),


    createStudent: (payload) => Axios.post(`${BASE_URL}/user`, payload, {headers : {...HEADERS, Authorization : getToken()}}).then(res => {
        return res.data
    }).catch(res => {
        return res.response.data
    }),

    updateStudent: (payload) => Axios.put(`${BASE_URL}/user`, payload, {headers : {...HEADERS, Authorization : getToken()}}).then(res => {
        return res.data
    }).catch(res => {
        return res.response.data
    }),

    deleteStudent: (idStudent) => Axios.put(`${BASE_URL}/user`, {id : idStudent, deleted : Constants.DELETED_VAL.TRUE}, {headers : {...HEADERS, Authorization : getToken()}})
    

}