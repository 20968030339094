import crmServices from '../../services/crmServices'

export const FETCH_ISSUES = 'FETCH_ISSUES'

export const loadIssues = (issues) => ({
    type: FETCH_ISSUES,
    payload: { issues }
})

export const fetchIssues = (filters) => (dispatch) => {
    //Esto espera una respuesta, es un promise al backend
    crmServices.getIssuesFiltered(filters)
        .then((issues) => dispatch(loadIssues(issues)))
        .catch((error) => console.log(error))
}

