import Constants from './constants';
import Cookies from 'js-cookie';
import Moment from 'moment';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import colombiaHoliday from 'colombia-holidays';
import Strings from './strings';

const Utils = {

    setLocalStorage: (key, item) => localStorage.setItem(key, item),
    getLocalStorage: (item) => localStorage.getItem(item),
    removeLocalStorage: (item) => localStorage.removeItem(item),
    cleanLocalStorage: () => localStorage.clear(),

    //Language services
    getLangLocalStorage: () => Utils.getLocalStorage(Constants.LANGUAGE_KEY),
    removeLangLocalStorage: () => Utils.removeLocalStorage(Constants.LANGUAGE_KEY),
    setLangLocalStorage: (item) => Utils.setLocalStorage(Constants.LANGUAGE_KEY, item),

    //______________________________________________Start Cookies services_______________________________________
    setCookieToken: (item, expires) => {
        //Transformar el tiempo de expiración a días para configurar la Cookie
        //1 dia tiene 86400 segundos
        expires = ((Moment(new Date(expires)).diff(new Date(), 'seconds')) * 1) / 86400
        Cookies.set(Constants.TOKEN_KEY, item, { expires })
    },
    getCookieToken: () => Cookies.get(Constants.TOKEN_KEY),
    removeCookieToken: () => Cookies.remove(Constants.TOKEN_KEY),
    removeCookieEncrypt: () => Cookies.remove(Constants.ENCRYPTED_KEY),
    //______________________________________________End Cookie Services_______________________________________


    isNullOrEmpty: function (obj) {
        return obj === null || obj === undefined || obj === '';
    },

    isEmptyObject: function (obj) {
        return this.isNullOrEmpty(obj) || Object.keys(obj).length === 0;
    },


    //______________________________________________Start Crypto Utils________________________________________
    encryptAndSetUserData: (object) => {
        const { id, email, first_name, last_name, user_type, role_id, group_id, group_code, token, expires, business_group, image } = object;
        const _object = { id, email, first_name, last_name, user_type, role_id, group_id, group_code, business_group, token, image };

        var encoded = CryptoJS.AES.encrypt(JSON.stringify(_object), Constants.AES_PHRASE).toString();

        let _expires = ((Moment(new Date(expires)).diff(new Date(), 'seconds')) * 1) / 86400
        Cookies.set(Constants.ENCRYPTED_KEY, encoded, { expires: _expires })
    },

    decryptUserData: () => {
        const encryptedData = Cookies.get(Constants.ENCRYPTED_KEY)
        if (encryptedData) {
            return JSON.parse(CryptoJS.AES.decrypt(encryptedData, Constants.AES_PHRASE).toString(CryptoJS.enc.Utf8));
        }
        return {};
    },
    //______________________________________________End Crypto Utils________________________________________
    getParamsArrayToGetPath: (prmstr) => {
        var params = {};
        var prmarr = prmstr.split("&");
        for (var i = 0; i < prmarr.length; i++) {
            var tmparr = prmarr[i].split("=");
            params[tmparr[0]] = tmparr[1];
        }
        return params;
    },

    /**
     * Insert a json key in string with format {}
     *  Eg: "My name is {name}"
     * */
    template: (string, jsonObject) => {
        const keys = Object.keys(jsonObject);
        for (let item in keys) {
            string = string.replace(`{${keys[item]}}`, jsonObject[keys[item]])
        }
        return string;
    },

    /**
     * Compare two hours and return true or false, used to check start and end hour on handle save.
     * @param startHour must be a moment object
     * @param endHour must be a moment object
     * @returns ans = true || false
     */
    compareHours: (startHour, endHour) => {
        let ans = startHour.isBefore(endHour);
        return ans;
    },

    /**
     * Calculate diference in hours of two times.
     * @param startHour must be a moment object
     * @param endHour must be a moment object
     * @returns hours = true || false
     */
    calculateDuration: (startHour, endHour) => {
        let hours = endHour.diff(startHour, 'hours', true)
        return hours;
    },

    getHoursNumber: (hour) => {
        let momentHour = moment(hour, 'HH:mm:ss');
        return parseInt(momentHour.hours() + (momentHour.minutes() / 60));
    },

    /**
     * Convert file to base64 string 
     * @param file file to convert
     */
    fileToBase64: (file) => {
        return new Promise(resolve => {

            var reader = new FileReader();
            // Read file content on file loaded event
            reader.onload = function (event) {
                resolve(event.target.result);
            };

            // Convert data to base64 
            reader.readAsDataURL(file);
        });
    },

    /**
     * Get random color RGB
     */
    getRandomColor: () => {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    },

    /**
     * Get holiday array in format -> { holiday: 'YYYY-MM-DD', celebrationDay: 'YYYY-MM-DD', celebration: 'Pascua' }
     */
    getHolidayDays: (currentYear = moment().format('YYYY')) => {
        // Generate holiday colombia array's
        let holidayArray = colombiaHoliday.getColombiaHolidaysByYear(currentYear);
        const currentMonth = moment().format('MM');
        if (currentMonth == 12) {
            holidayArray = [...holidayArray, ...colombiaHoliday.getColombiaHolidaysByYear(currentYear + 1)]
        }
        return holidayArray;
    },

    /**
     * Get class state name
     */
    getClassStateName: (stateCode) => {
        switch (stateCode) {
            case Constants.CLASS_STATE.CREATED:
                return Strings.created;
            case Constants.CLASS_STATE.RESCHEDULED_BY_TEACHER:
                return Strings.rescheduled_by_teacher;
            case Constants.CLASS_STATE.FINISHED:
                return Strings.finished;
            case Constants.CLASS_STATE.INVALID_CANCELATION:
                return Strings.invalid_cancelation;
            case Constants.CLASS_STATE.LATE_CANCELATION:
                return Strings.late_cancelation;
            case Constants.CLASS_STATE.EARLY_CANCELATION:
                return Strings.early_cancelation;
            case Constants.CLASS_STATE.CALAMITY_CANCELATION:
                return Strings.calamity_cancelation;
        }
    },

    formatScheduleInfo: (dateArray, timeInterval) => {
        let pivotDay = { day: null };
        let finalArr = [];
        let reduceArray = [];
        // console.log("El arreglo ", dateArray)
        dateArray.map((item, index) => {
            if (item.day != pivotDay.day || moment(item.hour, 'hh:mm a').format('HH:mm') != moment(dateArray[index - 1].hour, 'hh:mm a').add(timeInterval, 'm').format('HH:mm')) {
                //First time
                // if(dateArray[index - 1])
                // console.log(moment(item.hour, 'hh:mm a').format('HH:mm') , moment(dateArray[index - 1].hour, 'hh:mm a').add(timeInterval, 'm').format('HH:mm'))
                if (pivotDay.day != null) {
                    const lastDay = dateArray[index - 1];
                    reduceArray.push({
                        start_hour: moment(pivotDay.hour, 'hh:mm a').format('HH:mm'),
                        end_hour: moment(lastDay.hour, 'hh:mm a').format('HH:mm')
                    })
                }

                //Making json structure of endpoint createSchedule
                if (item.day != pivotDay.day && pivotDay.day != null) {
                    finalArr.push({ weekday: pivotDay.day, workingTime: reduceArray });
                    reduceArray = [];
                }

                pivotDay = item;
            }
            //Last position
            if (index == dateArray.length - 1) {
                reduceArray.push({
                    start_hour: moment(pivotDay.hour, 'hh:mm a').format('HH:mm'),
                    end_hour: moment(item.hour, 'hh:mm a').format('HH:mm')
                });
                finalArr.push({ weekday: pivotDay.day, workingTime: reduceArray })
            }
        })
        return finalArr;
    },

    /**
     * Get states array to next state update
     */
    getClassStates: (prevState, options) => {
        switch (prevState) {
            case Constants.CLASS_STATE.CREATED:
                return options.map(item => {
                    if (item.value != Constants.CLASS_STATE.RESCHEDULED_BY_TEACHER &&
                        item.value != Constants.CLASS_STATE.PENDING_RESCHEDULING &&
                        item.value != Constants.CLASS_STATE.FINISHED &&
                        item.value != Constants.CLASS_STATE.CALAMITY_CANCELATION &&
                        item.value != Constants.CLASS_STATE.EARLY_CANCELATION &&
                        item.value != Constants.CLASS_STATE.INVALID_CANCELATION &&
                        item.value != Constants.CLASS_STATE.CANCELED &&
                        item.value != Constants.CLASS_STATE.LATE_CANCELATION) {
                        item.isDisabled = true;
                    } else {
                        item.isDisabled = false;
                    }
                    return item;
                });
            case Constants.CLASS_STATE.FINISHED:
                return options.map(item => {
                    if (true) {
                        item.isDisabled = true;
                    } else {
                        item.isDisabled = false;
                    }
                    return item;
                });
            case Constants.CLASS_STATE.RESCHEDULED_BY_TEACHER:
                return options.map(item => {
                    if (true) {
                        item.isDisabled = true;
                    } else {
                        item.isDisabled = false;
                    }
                    return item;
                });
            case Constants.CLASS_STATE.PENDING_RESCHEDULING:
                return options.map(item => {
                    if (true) {
                        item.isDisabled = false;
                    } else {
                        item.isDisabled = false;
                    }
                    return item;
                });
            default:
                return options.map(item => {
                    item.isDisabled = true;
                    return item;
                });
        }
    },

    /**
     * Get all properties for a badge
     * @param item (object) all object data. Only need state for any case. Otherwise need penalty_hour for classes cases.
     * @returns badgeProperties with all properties required to render a badge.
     */
    getpropertiesForBadge: (item) => {

        let colorBadge, value, textColor;

        switch (item.state) {
            case (Constants.BADGES.ACTIVE):
                colorBadge = "#20a8d8";
                value = Strings.active;
                textColor = "white";
                break;
            case (Constants.BADGES.INACTIVE):
                colorBadge = "#f86c6b";
                value = Strings.inactive;
                textColor = "white";
                break;
            case (Constants.BADGES.IN_PROGRESS):
                colorBadge = "#ffc107";
                value = Strings.in_progress;
                textColor = "black";
                break;
            case (Constants.BADGES.FINISHED):
                colorBadge = "#4dbd74";
                value = Strings.finished;
                textColor = "white";
                break;
            case (Constants.BADGES.RESCHEDULED_BY_TEACHER):
                colorBadge = Constants.COLORS.TEACHER_RESCHEDULE_CLASS;
                value = Strings.rescheduled_by_teacher;
                textColor = "white";
                break;
            case (Constants.BADGES.CALAMITY_CANCELATION):
                colorBadge = Constants.COLORS.CALAMITY_CANCELED_CLASS;
                value = Strings.calamity_cancellation;
                textColor = "white";
                break;
            case (Constants.BADGES.STUDYING):
                colorBadge = "#ffc107";
                value = Strings.studying;
                textColor = "black";
                break;
            case (Constants.BADGES.KICKED):
                colorBadge = "#f86c6b";
                value = Strings.kicked;
                textColor = "white";
                break;
            case (Constants.BADGES.CANCELED):
                colorBadge = "#f86c6b";
                value = Strings.canceled;
                textColor = "white";
                break;
            case (Constants.BADGES.CREATED):
                colorBadge = Constants.COLORS.CREATED_CLASS;
                value = Strings.created;
                textColor = "black";
                break;
            case (Constants.BADGES.CANCELED):
                colorBadge = "#f86c6b";
                value = Strings.canceled;
                textColor = "white";
                break;
            case (Constants.BADGES.INVALID_CANCELATION):
                colorBadge = Constants.COLORS.INVALID_CANCELED_CLASS;
                value = Strings.invalid_cancelation;
                textColor = "white";
                break;
            case (Constants.BADGES.REGISTERED):
                colorBadge = "#c8ced3";
                value = Strings.registered;
                textColor = "black";
                break;
            case (Constants.BADGES.LATE_CANCELATION):
                if (!Utils.isNullOrEmpty(item.penalty_hour) && item.penalty_hour !== '00:00:00') {
                    colorBadge = 'linear-gradient(90deg, rgba(102,16,242,1) 0%, rgba(248,108,107,1) 100%)';
                } else {
                    colorBadge = Constants.COLORS.LATE_CANCELED_CLASS;
                }
                value = Strings.late_cancelation;
                textColor = "white";
                break;
            case (Constants.BADGES.EARLY_CANCELATION):
                if (!Utils.isNullOrEmpty(item.penalty_hour) && item.penalty_hour !== '00:00:00') {
                    colorBadge = Constants.COLORS.INVALID_CANCELED_CLASS;
                } else {
                    colorBadge = Constants.COLORS.EARLY_CANCELED_CLASS;
                }
                value = Strings.early_cancellation;
                textColor = "white";
                break;
            case (Constants.BADGES.WAITING):
                colorBadge = "#ff6600";
                value = Strings.waiting;
                textColor = "white";
                break;
            case (Constants.BADGES.REJECTED):
                colorBadge = "#f86c6b";
                value = Strings.rejected;
                textColor = "white";
                break;
        }

        const badgeProperties = {
            style: { background: colorBadge, color: textColor },
            label: value
        };

        return badgeProperties;
    },

    // Accepts the array and key
    groupBy: (array, key) => {
        // Return the end result
        return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
        }, {}); // empty object is the initial value for result object
    },
}

export default Utils;