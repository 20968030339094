import { FETCH_TEACHERS } from '../actions/teachers';

const initialState = {
  count:0,
  teachersList: [],
  error: false
};

const teachers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEACHERS:
      const { teachers } = action.payload
      return {
        count: teachers.data.count,
        teachersList : teachers.data.list,
        error: !teachers.success
      }

    default: return state
  }
}

export default teachers