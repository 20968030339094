import { createStore, applyMiddleware } from 'redux';
import rootReducers from './redux/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import Thunk from 'redux-thunk'


export default function () {
    return createStore(rootReducers, composeWithDevTools(applyMiddleware(Thunk)))
}


