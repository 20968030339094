//Cambiarlo para funcionar con el endpoint ./usersServices
import userServices from '../../services/userServices'

export const FETCH_USERS = 'FETCH_USERS'

export const loadUsers = (users) => ({
  type: FETCH_USERS,
  payload: { users }
})

export const fetchUsers = (filters) => (dispatch) =>{
  //Esto espera una respuesta, es un promise al backend
  userServices.getUsersFiltered(filters)
  .then((users) => dispatch(loadUsers(users)))
  .catch((error) => console.log(error))
}

