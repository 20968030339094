// External libraries
import Axios from 'axios'
// Utils
import Utils from '../utils/Utils'
import Constants from '../utils/constants'
// Enviroments
import enviroment from '../enviroments/enviroments';
const HEADERS = { 'Content-Type': 'application/json' }

const BASE_URL = process.env.REACT_APP_API_URL;
// New Monolithic backend URL
const URLV2 = enviroment.getUrl();

const getToken = () => Utils.getCookieToken();

export default {
    //Trae la informacion programado en el EndPoint. Lo trae en res y trae todo el objeto y el estado
    getTeachers: (filters) => Axios.post(`${BASE_URL}/getTeachersFiltered`, filters, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => res.data),

    getTeacher: (id) => Axios.get(`${BASE_URL}/getUser/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    
    getTeacherCalendar: (id) => Axios.get(`${BASE_URL}/getTeacherCalendar/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data).catch(res => res.response.data),

    getTeachersFiltered: (payload) => Axios.post(`${BASE_URL}/getTeachersFiltered`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => {
        return res.data
    }).catch(res => {
        return res.response.data
    }),

    getPendingClassesByTeacher: (teacherId) => Axios.get(`${BASE_URL}/getPendingClassesByTeacher/${teacherId}`, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => {
        return res.data
    }),

    /*
        This function fetch all active Groups that a teacher has
    */
    getActiveGroupsByTeacher : (teacherId) => Axios.get(`${URLV2}/user/teacher/groups/active?idTeacher=${teacherId}`),

    createTeacher: (payload) => Axios.post(`${BASE_URL}/user`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => {
        return res.data
    }).catch(res => {
        return res.response.data
    }),

    updateTeacher: (payload) => Axios.put(`${BASE_URL}/user`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => {
        return res.data
    }).catch(res => {
        return res.response.data
    }),

    deleteTeacher: (idTeacher) => Axios.put(`${BASE_URL}/user`, { id: idTeacher, deleted: Constants.DELETED_VAL.TRUE }, { headers: { ...HEADERS, Authorization: getToken() } })
}