import { FETCH_USERS } from '../actions/users';

const initialState = {
  count: 0,
  usersList: [],
  error: false
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS:
      const { users } = action.payload
      return {
        count: users.data.count,
        usersList : users.data.list,
        error: !users.success
      }

    default: return state
  }
}

export default users