import { FETCH_DEMO_CLASSES, FETCH_DEMO_AND_PLACEMENT_CLASSES } from '../actions/individualClasses';

const initialState = {
    count: 0,
    individualClassesList: [],
    error: false
};

const IndividualClasses = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEMO_CLASSES:
            const { individualClasses } = action.payload
            return {
                count: individualClasses.data.count,
                individualClassesList: individualClasses.data.list,
                error: !individualClasses.success
            }
        case FETCH_DEMO_AND_PLACEMENT_CLASSES:
            const { demoAndPlacementClasses } = action.payload
            return {
                count: demoAndPlacementClasses.data.count,
                individualClassesList: demoAndPlacementClasses.data.list,
                error: !demoAndPlacementClasses.success
            }
        default: return state
    }
}

// const demoAndPlacementClasses = (state = initialState, action) => {
//     switch (action.type) {
//         case FETCH_DEMO_AND_PLACEMENT_CLASSES:
//             const { demoAndPlacementClasses } = action.payload
//             return {
//                 count: demoAndPlacementClasses.data.count,
//                 demoAndPlacementClassesList: demoAndPlacementClasses.data.list,
//                 error: !demoAndPlacementClasses.success
//             }
//         default: return state
//     }
// }

export default IndividualClasses 