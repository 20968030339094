//Cambiarlo para funcionar con el endpoint ./groupServices
import individualClassesServices from '../../services/IndividualClassServices'
import responseMessages from '../../utils/responseMessages'
import moment from 'moment'

export const FETCH_DEMO_CLASSES = 'FETCH_DEMO_CLASSES'
export const FETCH_DEMO_AND_PLACEMENT_CLASSES = 'FETCH_DEMO_AND_PLACEMENT_CLASSES'

export const loadIndividualClasses = (individualClasses) => ({
    type: FETCH_DEMO_CLASSES,
    payload: { individualClasses }
})

export const loadDemoAndPlacementClasses = (demoAndPlacementClasses) => ({
    type: FETCH_DEMO_AND_PLACEMENT_CLASSES,
    payload: { demoAndPlacementClasses }
})

export const fetchIndividualClasses = (filters) => (dispatch) => {
    //Esto espera una respuesta, es un promise al backend
    individualClassesServices.getIndividualClassesFiltered(filters)
        .then((individualClasses) => {
            switch (individualClasses.code) {
                case responseMessages.SUCCESS.code:
                    individualClasses.data.list.map((individualClass) => {
                        individualClass.start_hour = moment(individualClass.start_hour, "hh:mm:ss").format("hh:mm a")
                        individualClass.end_hour = moment(individualClass.end_hour, "hh:mm:ss").format("hh:mm a")
                    })
                    dispatch(loadIndividualClasses(individualClasses));
                    break;
            }
        }).catch((error) => console.log(error))
}

export const fetchDemoAndPlacementClasses = (filters) => (dispatch) => {
    //Esto espera una respuesta, es un promise al backend
    individualClassesServices.getDemoAndPlacementClassesFiltered(filters)
        .then((demoAndPlacementClasses) => {
            switch (demoAndPlacementClasses.code) {
                case responseMessages.SUCCESS.code:
                    demoAndPlacementClasses.data.list.map((individualClass) => {
                        individualClass.start_hour = moment(individualClass.start_hour, "hh:mm:ss").format("hh:mm a")
                        individualClass.end_hour = moment(individualClass.end_hour, "hh:mm:ss").format("hh:mm a")
                    })
                    dispatch(loadDemoAndPlacementClasses(demoAndPlacementClasses));
                    break;
            }
        }).catch((error) => console.log(error))
}
