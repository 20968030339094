import Axios from 'axios'
import Utils from '../utils/Utils'
import Constants from '../utils/constants'

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1'
const HEADERS = { 'Content-Type': 'application/json' }

const getToken = () => Utils.getCookieToken();

export default {
    //-------------------------------------------- POST -----------------------------------------------
    createIssue: (payload) => Axios.post(`${BASE_URL}/createIssue`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getIssuesFiltered: (filters) => Axios.post(`${BASE_URL}/getIssuesFiltered`, filters, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    changeIssueState: (payload) => Axios.post(`${BASE_URL}/changeIssueState`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    //-------------------------------------------- POST -----------------------------------------------

    //-------------------------------------------- GET ------------------------------------------------
    getIssue: (id) => Axios.get(`${BASE_URL}/getIssue/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getIssues: () => Axios.get(`${BASE_URL}/getIssues`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getAnnualIssues: () => Axios.get(`${BASE_URL}/getAnnualIssues`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    //-------------------------------------------- GET ------------------------------------------------

    //-------------------------------------------- PUT ------------------------------------------------
    updateIssue: (payload) => Axios.put(`${BASE_URL}/updateIssue`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    deleteIssue: (id) => Axios.put(`${BASE_URL}/deleteIssue/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    //-------------------------------------------- PUT ------------------------------------------------

    //-------------------------------------------- DEL ------------------------------------------------
    //Physical delete from DB
    removeIssue: (id) => Axios.delete(`${BASE_URL}/removeIssue/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    //-------------------------------------------- DEL ------------------------------------------------
}