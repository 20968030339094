//Cambiarlo para funcionar con el endpoint ./studentServices
import studentServices from '../../services/studentServices'

export const FETCH_STUDENTS = 'FETCH_STUDENTS'

export const loadStudents = (students) => ({
  type: FETCH_STUDENTS,
  payload: { students }
})

export const fetchStudents = (filters) => (dispatch) =>{
  //Esto espera una respuesta, es un promise al backend
  studentServices.getStudents(filters)
  .then((students) => dispatch(loadStudents(students)))
  .catch((error) => console.log(error))
}

