
//constants dictionary

export default {
    LIST: {
        USER_STATES_LIST: 1,
        GROUP_STATES_LIST: 2,
        DOCUMENT_TYPES_LIST: 3,
        GENRE_LIST: 4,
        CLASS_TYPE_LIST: 5,
        CLASS_STATE_LIST: 6,
        STREET_TYPES_LIST: 7,
        COMPANIES_LIST: 8,
        INDIVIDUAL_CLASS_TYPES_LIST: 9,
        PROJECT_LIST: 10,
        INDIVIDUAL_CLASS_MODALITY_LIST: 11,
        TREATMENTS_LIST: 12,
        USER_LANGUAGE_HOURS_ACTIONS_LIST: 13,
        INDIVIDUAL_CLASS_STATE_LIST: 14,
        ISSUE_STATES_LIST: 15,
        ISSUE_PRIORITIES_LIST: 16,
        ISSUE_TYPES_LIST: 17,
        ISSUE_RELATIONS_LIST: 18
    },

    BADGES: {
        ACTIVE: 'A',
        INACTIVE: 'I',
        IN_PROGRESS: 'IP',
        WAITING: 'W',
        CREATED: 'CR',
        FINISHED: 'F',
        RESCHEDULED_BY_TEACHER: 'R',
        PENDING_RESCHEDULING: 'PR',
        RESCHEDULED_BY_STUDENTS: "RBS",
        STUDYING: 'S',
        KICKED: 'K',
        CANCELED: 'C',
        INVALID_CANCELATION: 'IC',
        EARLY_CANCELATION: 'EC',
        CALAMITY_CANCELATION: 'CC',
        REGISTERED: 'RG',
        LATE_CANCELATION: 'R1H',
        REJECTED: 'RJ'
    },
    SCHEDULE_MODELS: {
        TEACHER: 'T',
        GROUPS: 'G'
    },
    ATTENDANCE_VALUES: {
        YES: 1,
        NO: 0,
    },

    CLASS_MODALITYS: {
        FACE_TO_FACE: 1,
        VIRTUAL: 2
    },

    LOCATION_STATE: {
        ACTIVE: 'A',
        INACTIVE: 'I'
    },

    GROUP_STATES: {
        ACTIVE: 'A',
        STUDYING: 'EN',
        FINISHED: 'F',
        CANCELED: 'C',
    },

    USER_STATUS: {
        REGISTERED: 'RG',
        ACTIVE: 'A',
        STUDYING: 'S',
        FINISHED: 'F',
        KICKED: 'K',
        INACTIVE: 'I',
        WAITING: 'W'
    },

    USER_TYPES: {
        STUDENT: '1',
        TEACHER: '2',
        ADMIN: '3',
        SUPERVISOR: '4',
        ADMINISTRATIVE_COORDINATOR: '5',
        ACADEMIC_COORDINATOR: '6'
    },

    CLASS_STATE: {
        CREATED: 'CR',
        IN_PROGRESS: 'IP',
        FINISHED: 'F',
        RESCHEDULED_BY_TEACHER: 'R',
        PENDING_RESCHEDULING: "PR",
        RESCHEDULED_BY_STUDENTS: 'RBS',
        LATE_CANCELATION: 'R1H',
        INVALID_CANCELATION: 'IC',
        EARLY_CANCELATION: 'EC',
        CALAMITY_CANCELATION: 'CC',
        CANCELED: 'C'
    },

    INDIVIDUAL_CLASS_STATES: {
        CREATED: 'CR',
        FINISHED: 'F',
        CANCELED: 'C'
    },

    PENDING_RESCHEDULING: {
        CLASS_PENDING_FOR_RESCHEDULING: 1,
        CLASS_NOT_PENDING_FOR_RESCHEDULING: 0
    },

    COLORS: {
        FINISHED_CLASS: "#4dbd74",
        CREATED_CLASS: "#c8ced3",
        LATE_CANCELED_CLASS: "#6610f2",
        INVALID_CANCELED_CLASS: "#f86c6b",
        CALAMITY_CANCELED_CLASS: "#000000",
        EARLY_CANCELED_CLASS: "#efaa0e",
        TEACHER_RESCHEDULE_CLASS: "#20a8d8",

        QUALIFY_INDIVIDUAL_CLASS: "#efaa0e",
        COMMENT_INDIVIDUAL_CLASS: "#20a8d8",
        FINISH_INDIVIDUAL_CLASS: "#4dbd74",
        CANCEL_INDIVIDUAL_CLASS: "#f86c6b"
    },

    CALENDAR_VIEWER_TYPES: {
        SCHEDULER: 1,
        NORMAL_CLASSES: 2,
        TUTORIALS: 3,
        DEMO_CLASS: 4,
        PLACEMENT_TEST: 5,
        STUFF_EVENTS: 6,
        INACTIVITY_EVENTS: 7
    },

    INDIVIDUAL_CLASS_TYPE: {
        DEMO_CLASS_CODE: 1,
        PLACEMENT_TEST_CODE: 2,
        TUTORIAL_CLASS_CODE: 3
    },

    INDIVIDUAL_CLASS_MODALITY: {
        FACE_TO_FACE: 1,
        VIRTUAL: 2
    },

    CLASS_TYPE: {
        NORMAL: 1,
        TUTORIAL: 2,
        RESCHEDULER: 3
    },

    FIRST_LOGIN_VAL: {
        FALSE: 0,
        TRUE: 1,
    },

    DELETED_VAL: {
        FALSE: 0,
        TRUE: 1,
    },

    PAGINATION_DEFAULT_PAGE_SIZE: 30,

    LANGUAGES: {
        ENGLISH: 1,
        DEUTSCH: 2,
        SPANISH: 3
    },

    PARAMETER_NAMES: {
        DEMO_CLASS_PRICE: 1,
        MINIMUM_DAYS_DEMO_CLASS: 2,
        INTERVAL_HOURS_SCHEDULER: 3,
        REQUIRED_MODAL_RATE_CLASS: 4,
        MINIMUN_DAYS_SCHEDULE_CLASS: 5,
        MINIMUN_MINUTES_FOR_PLACEMENT_AND_DEMO_CLASS: 6,
        MINIMUN_MINUTES_FOR_TUTORIAL_CLASS: 7,
        NUMBER_OF_LATE_CANCELLATIONS_PERMITTED: 8,
        NUMBER_OF_HOURS_TO_PENALIZE_LATE_CANCELATION: 9,
        PERCENTAGE_OF_HOURS_FOR_EARLY_CANCELATION: 10,
        NUMBER_PERCENT_ALERT_HOURS: 11,
        MAXIMUN_MINUTES_FOR_TUTORIAL_CLASS: 12,
        HOURS_LIMIT_RESCHEDULE_PER_SESSIONS: 13,
        HOURS_LIMIT_CALL_ATTENDANCE: 14
    },

    ISSUE_PRIORITIES: {
        LOW: 'L',
        MEDIUM: 'M',
        HIGH: 'H'
    },

    ISSUE_STATES: {
        CREATED: 'CR',
        IN_PROGRESS: 'IP',
        FINISHED: 'F',
        REJECTED: 'RJ'
    },

    ISSUE_TYPES: {
        COMPLAINT: 1,
        PETITION: 2,
        CLAIM: 3
    },

    ISSUE_RELATIONS: {
        GROUP: 'G',
        TEACHER: 'T',
        PLATFORM: 'P',
        ACADEMIC: 'A'
    },

    LANGUAGE_STATE: {
        ACTIVE: 'A',
        INACTIVE: 'I'
    },

    MIN_CHARACTER_ON_CHANGE_SEARCH: 2,

    USER_LANGUAGE_HOURS_ACTIONS: {
        CLASS_FINISH: 1,
        CLASS_CANCELED: 2,
        CLASS_LATE: 3,
        ADD_HOURS: 4,
        SUBTRACT_HOURS: 5,
        CLASS_PENALIZED: 6
    },

    CURRICULAR_PLAN_ACTIONS: {
        CREATE: "create",
        EDIT: "edit",
        LANGUAGE: "language",
        LANGUAGE_LEVEL: "language_level",
        STAGE: "stage",
        UNIT: "unit",
        TOPIC: "topic",
        CONTENT: "contents"
    },

    DEFAULT_PASSWORD: "tingo2020",

    HOLIDAY_TYPE: "holyday",
    TOKEN_KEY: '_tok',
    ENCRYPTED_KEY: "_tink",
    LANGUAGE_KEY: '_lang',

    STUDENTS_LINK: '/students/edit/',
    TEACHERS_LINK: '/teachers/edit/',
    GROUPS_LINK: '/groups/edit/',
    USERS_LINK: '/users/edit',
    CLASSES_LINK: '/groups/classes/',
    CLASS_LINK: '/edit/',
    INDIVIDUAL_CLASS_LINK: "/individualClasses/edit/",
    CLASS_ITEM_LINK: '/groups/items',
    STUDENT_INDIVIDUAL_CLASSES_LINK: '/studentIndividualClasses',
    CURRICULAR_PLAN_LINK: '/curricularPlanConfiguration',
    STUDENT_LOG: '/students/hoursLog',
    ISSUE_LINK: '/crm/issues/issue',
    ISSUES_STUDENTS_LINK: '/myIssues',
    ISSUE_STUDENTS_LINK: '/myIssues/issue',
    ISSUES_LINK: '/crm/issues',

    UPDATE_PASSWORD_CODES: {
        SAME_PASSWORDS: 0,
        EMPTY_PASSWORDS: 1,
        DIFFERENT_PASSWORDS_NO_EMPTY: 2,
        ONE_PASSWORD_EMPTY: 3,
    },

    //Button options
    QUALIFY: "qualify",
    CREATE: "create",
    EDIT: "edit",
    SWITCH_STATE: "switch_state",
    DISPLAY_TEACHER_GROUPS : "display_teacher_groups",
    DISPLAY_LOG: "display_log",
    ADD_HOURS: "addHours",
    DELETE: "delete",
    CLASSES: "classes",
    ATTENDANCE: "attendance",
    SAVE: "save",
    CLOSE: "close",
    FINISH_CLASS: "finish_class",
    CHANGE_STATE: "change_state",
    COMMENT: "comment",
    REPORTS: "reports",
    VISUALIZE: "visualize",
    APPROVE: "approve",
    FILL: "fill",
    ASSIGN: "assign",
    BACK: "back",
    FINISH: "finish",
    CANCEL: "cancel",
    RATES: 'rates',

    AES_PHRASE: "28#.7I?n6T0=$D0tA"
}