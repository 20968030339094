import Axios from 'axios'
import Utils from '../utils/Utils'

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1'
const HEADERS = { 'Content-Type': 'application/json' }

const getToken = () => Utils.getCookieToken();


export default {

    getUsers: () => Axios.get(`${BASE_URL}/getAllUsers`, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => res.data),
    getUserLocation: (userId) => Axios.get(`${BASE_URL}/getUserLocationByUserId/${userId}`, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => res.data),
    getUser: (id) => Axios.get(`${BASE_URL}/getUser/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getAdminUserByFullname: (name) => Axios.get(`${BASE_URL}/getAdminUserByFullname/${name}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),

    updateUserLocation: (payload) => Axios.put(`${BASE_URL}/userLocation`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => res.data).catch(res => res.response.data),

    updateUser: (payload) => Axios.put(`${BASE_URL}/user`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => {
        return res.data
    }).catch(res => {
        return res.response.data
    }),

    createUser: (payload) => Axios.post(`${BASE_URL}/user`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then(res => res.data).catch(res => res.response.data),
    createUserLocation: (payload) => Axios.post(`${BASE_URL}/userLocation`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),


    getUsersFiltered: (filters) => Axios.post(`${BASE_URL}/getUsersFiltered`, filters, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),


    //_______________________________________Role services______________________________________________________________________________
    addRol: (roleName) => Axios.post(`${BASE_URL}/role`, roleName, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    deleteRole: (roleId) => Axios.delete(`${BASE_URL}/deleteRole/${roleId}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    deleteNavigationRole: (payload) => Axios.delete(`${BASE_URL}/deleteNavigationRole`, { data: payload, headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getAllRoles: () => Axios.get(`${BASE_URL}/getAllRoles`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    createNavigationRole: (payload) => Axios.post(`${BASE_URL}/createNavigationRole`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getNavigationRole: (roleId) => Axios.get(`${BASE_URL}/getNavigationsByRole/${roleId}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
}