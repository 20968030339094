import Axios from 'axios'
import Utils from '../utils/Utils'

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1'
const HEADERS = { 'Content-Type': 'application/json' }

const getToken = () => Utils.getCookieToken();

export default {
    //-------------------------------------------- Demo Class services --------------------------------

    //-------------------------------------------- POST -----------------------------------------------
    createIndividualClass: (payload) => Axios.post(`${BASE_URL}/createIndividualClass`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    createMultipleIndividualClass: (payload) => Axios.post(`${BASE_URL}/createMultipleIndividualClass`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getIndividualClassesFiltered: (filters) => Axios.post(`${BASE_URL}/getIndividualClassesFiltered`, filters, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getDemoAndPlacementClassesFiltered: (filters) => Axios.post(`${BASE_URL}/getDemoAndPlacementClassesFiltered`, filters, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    rescheduleIndividualClass: (payload) => Axios.post(`${BASE_URL}/rescheduleIndividualClass`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getDailyIndividualClassesByTeacher: (payload) => Axios.post(`${BASE_URL}/getDailyIndividualClassesByTeacher`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    finishIndividualClass: (payload) => Axios.post(`${BASE_URL}/finishIndividualClass`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    //-------------------------------------------- POST -----------------------------------------------

    //-------------------------------------------- GET ------------------------------------------------
    getIndividualClass: (id) => Axios.get(`${BASE_URL}/getIndividualClass/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    getIndividualClasses: () => Axios.get(`${BASE_URL}/getIndividualClasses`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    //-------------------------------------------- GET ------------------------------------------------

    //-------------------------------------------- PUT ------------------------------------------------
    updateIndividualClass: (payload) => Axios.put(`${BASE_URL}/updateIndividualClass`, payload, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    //-------------------------------------------- PUT ------------------------------------------------

    //-------------------------------------------- DEL ------------------------------------------------
    deleteIndividualClass: (id) => Axios.delete(`${BASE_URL}/deleteIndividualClass/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),

    //Physical delete from DB
    removeIndividualClass: (id) => Axios.delete(`${BASE_URL}/removeIndividualClass/${id}`, { headers: { ...HEADERS, Authorization: getToken() } }).then((res) => res.data),
    //-------------------------------------------- DEL ------------------------------------------------

}